<template>
  <!--  Div  container for paypal  payment status check-->
  <div
    v-if="payout && isPaypal"
    class="d-flex flex-column justify-center align-center"
  >
    <v-container v-if="showPaypalLoader" style="height: 400px">
      <v-row align-content="center" class="fill-height" justify="center">
        <v-col class="text-center loadercontainer" cols="12">
          {{ $t("ConfirmDisbursePayout.loadermessage") }}
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            height="6"
            indeterminate
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
    <v-spacer class="pb-10" />

    <v-icon
      v-if="!isPaymentDeclined && !showPaypalLoader"
      class="pt-15"
      color="success"
      size="75"
      data-test="check-mark-icon"
    >
      mdi-check-decagram-outline
    </v-icon>
    <!-- Div with heading message-->

    <div
      class="text-h3 pt-15"
      :class="{ 'success--text': claimed, 'text-center': claimed }"
      data-test="payout-success-message"
    >
      <div v-if="inReview">
        {{
          $t("ConfirmDisbursePayout.inReviewMessage", {
            merchant: formattedGroupName,
          })
        }}
      </div>
      <div v-if="inPaymentReviewOrHoldStatus">
        {{ $t("ConfirmDisbursePayout.inPaymentReviewOrHoldMessage") }}
      </div>
      <div v-if="isPaymentDeclined">
        {{ $t("ConfirmDisbursePayout.payoutDeclined") }}
      </div>
      <!-- <div v-if="(!inReview && !inPaymentReviewOrHoldStatus && !isDeclined || !claimed)">{{ successMsg }}
      </div> -->
      <div v-if="displaygenricMessage">{{ successMsg }}</div>
    </div>

    <template v-if="claimed">
      <div class="py-10" data-test="claimed-notice">
        {{ $t("ConfirmDisbursePayout.claimedNotice") }}
      </div>
      <div data-test="claimed-instruction">
        {{
          $t("ConfirmDisbursePayout.claimedInstruction", {
            txId: payout.transactionId,
          })
        }}
      </div>
    </template>
    <!-- Need to check the  Decline state-->
    <template v-else>
      <div v-if="!showPaypalLoader" class="text-center">
        <div class="pt-10" data-test="processed-date">
          <span class="font-weight-bold">
            {{
              inReview
                ? $t("ConfirmDisbursePayout.paymentAccepted")
                : $t("ConfirmDisbursePayout.processed")
            }}
          </span>
          {{ currentDate() }}
        </div>

        <div v-if="inReview" data-test="payout-expected-deposit-date">
          <span class="font-weight-bold">
            {{ $t("ConfirmDisbursePayout.payoutExpectedDepositDate") }} </span
          >{{ shortDate(payoutExpectedDepositDate) }}
        </div>

        <div class="pt-2" data-test="transaction-id">
          <span class="font-weight-bold"
            >{{ $t("ConfirmDisbursePayout.transactionId") }} </span
          >{{ payout.transactionId }}
        </div>
      </div>

      <div
        v-if="checkImageUrl"
        class="d-flex align-center w-full pt-8 justify-center"
      >
        <v-img :src="checkImageUrl" data-test="check-image" />
      </div>

      <div v-if="!showPaypalLoader" class="pt-8">
        <v-btn
          class="mx-2"
          tile
          variant="outlined"
          elevation="0"
          color="primary"
          min-width="125"
          data-test="done-button"
          @click="done"
        >
          {{ $t("ConfirmDisbursePayout.done") }}
        </v-btn>
      </div>
    </template>
  </div>

  <!-- Other Than Paypal method -->
  <div
    v-if="payout && !isPaypal"
    class="d-flex flex-column justify-center align-center"
  >
    <v-spacer class="pb-10" />

    <v-icon class="pt-15" color="success" size="75" data-test="check-mark-icon">
      mdi-check-decagram-outline
    </v-icon>

    <div
      class="text-h3 pt-15"
      :class="{
        'success--text': claimed,
        'text-center': claimed || isVisaPlus,
      }"
      data-test="payout-success-message"
    >
      {{
        inReview
          ? $t("ConfirmDisbursePayout.inReviewMessage", {
              merchant: formattedGroupName,
            })
          : inPaymentReviewOrHoldStatus
          ? $t("ConfirmDisbursePayout.inPaymentReviewOrHoldMessage")
          : successMsg
      }}
    </div>
    <!-- Enabled By Visa+-->
    <div
      v-if="isVisaPlus"
      class="align-center justify-center enabledByVisaRegister"
    >
      <enabledby-Visa data-test="EnabledBy-Visalogo"> </enabledby-Visa>
    </div>
    <template v-if="claimed">
      <div class="py-10" data-test="claimed-notice">
        {{ $t("ConfirmDisbursePayout.claimedNotice") }}
      </div>
      <div data-test="claimed-instruction">
        {{
          $t("ConfirmDisbursePayout.claimedInstruction", {
            txId: payout.transactionId,
          })
        }}
      </div>
    </template>

    <template v-else>
      <div v-if="isECheck" class="pt-5" data-test="eCheck-info">
        {{ $t("ConfirmDisbursePayout.eCheckMessage") }}
      </div>

      <div class="text-center">
        <div class="pt-10" data-test="processed-date">
          <span class="font-weight-bold">
            {{
              inReview
                ? $t("ConfirmDisbursePayout.paymentAccepted")
                : $t("ConfirmDisbursePayout.processed")
            }}
          </span>
          {{ currentDate() }}
        </div>

        <div v-if="inReview" data-test="payout-expected-deposit-date">
          <span class="font-weight-bold">
            {{ $t("ConfirmDisbursePayout.payoutExpectedDepositDate") }} </span
          >{{ shortDate(payoutExpectedDepositDate) }}
        </div>

        <div class="pt-2" data-test="transaction-id">
          <span class="font-weight-bold"
            >{{ $t("ConfirmDisbursePayout.transactionId") }} </span
          >{{ payout.transactionId }}
        </div>
      </div>

      <div
        v-if="checkImageUrl"
        class="d-flex align-center w-full pt-8 justify-center"
      >
        <v-img :src="checkImageUrl" data-test="check-image" />
      </div>

      <div class="pt-8">
        <v-btn
          class="mx-2"
          tile
          variant="outlined"
          elevation="0"
          color="primary"
          min-width="125"
          data-test="done-button"
          @click="done"
        >
          {{ $t("ConfirmDisbursePayout.done") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<script>
import { mdiCheckDecagramOutline } from "@mdi/js";
import { format } from "date-fns";
import { usePayoutStore } from "@/stores/payout";
import { usePayoutMethodStore } from "@/stores/payout-method";
import { useTitle } from "@/composables/title";
import { i18n } from "@/plugins/i18n";
import { useDialogPanelStore } from "@/stores/dialog-panel";
import { shortDate } from "@/filters/filters";
import EnabledbyVisa from "@/assets/img/enabledbyVisa.svg?inline";
import {
  isClaimed,
  isIR,
  isPaymentReviewOrHoldStatus,
  isMultiRecipient,
  getOtherRecipient,
  getRecipient,
  isPaymentInprogress,
  isDeclined,
} from "@/utils/payout";
import { ref, computed } from "vue";
import { currency } from "@/filters/filters";

export default {
  name: "ConfirmDisbursePayout",

  components: {
    EnabledbyVisa,
  },

  props: {
    checkImageUrl: { type: String, default: "" },
  },

  setup() {
    const dialogPanelStore = useDialogPanelStore();
    const payoutStore = usePayoutStore();
    const payoutMethodStore = usePayoutMethodStore();

    const inReview = ref(false);
    const inPaymentReviewOrHoldStatus = ref(false);
    const payoutExpectedDepositDate = ref("");
    const iRObj = isIR();
    inReview.value = iRObj.isIR;
    const paymentReviewObj = isPaymentReviewOrHoldStatus("PR");
    const paymentHoldObj = isPaymentReviewOrHoldStatus("PH");
    inPaymentReviewOrHoldStatus.value =
      paymentReviewObj.PR || paymentHoldObj.PH;

    const declinestat = computed(() => {
      return isDeclined(payoutStore.selected);
    });
    if (inReview.value) {
      payoutExpectedDepositDate.value = iRObj.payoutExpectedDepositDate;
      useTitle(i18n.global.t("ConfirmDisbursePayout.inReviewTitle"));
    } else {
      if (declinestat.value) {
        useTitle(i18n.global.t("ConfirmDisbursePayout.inDeclineTitle"));
      } else {
        useTitle(i18n.global.t("ConfirmDisbursePayout.title"));
      }
    }

    return {
      dialogPanelStore,
      payoutStore,
      payoutMethodStore,
      inReview,
      payoutExpectedDepositDate,
      shortDate,
      inPaymentReviewOrHoldStatus,
    };
  },

  data() {
    return {
      mdiCheckDecagramOutline,
      showPaypalLoader: false,
      totalTimespan: 30,
      currentTimespan: 1,
      intervalId: null,
      displaygenricMessage: false,
      visaPlusPayeeName: "",
      visaPlusAmount: "",
    };
  },

  computed: {
    payout() {
      return this.payoutStore.selected;
    },

    successMsg() {
      return this.claimed
        ? i18n.global.t("ConfirmDisbursePayout.payoutClaimed")
        : this.isVisaPlus
        ? i18n.global.t("ConfirmDisbursePayout.payoutVisaPlusSuccess", {
            amount: this.visaPlusAmount,
            payeeName: this.visaPlusPayeeName,
          })
        : i18n.global.t("ConfirmDisbursePayout.payoutSuccess");
    },

    updatePageTitle() {
      return isDeclined(this.payout)
        ? useTitle(i18n.global.t("ConfirmDisbursePayout.inDeclineTitle"))
        : "";
    },

    claimed() {
      return isClaimed(this.payout);
    },

    isECheck() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "ECHECK" ||
          getOtherRecipient(this.payout).source === "ECHECK"
        );
      } else {
        if (this.payoutMethodStore.selected)
          return this.payoutMethodStore.selected.source === "ECHECK";
        else return false;
      }
    },
    // IS Visa+
    isVisaPlus() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "VISAPLUS" ||
          getOtherRecipient(this.payout).source === "VISAPLUS"
        );
      } else {
        if (this.payoutMethodStore.selected)
          return this.payoutMethodStore.selected.source === "VISAPLUS";
        else return false;
      }
    },
    isPaymentDeclined() {
      return isDeclined(this.payout);
    },
    // PAYPAL
    isPaypal() {
      if (isMultiRecipient(this.payout)) {
        return (
          getRecipient(this.payout).source === "PAYPAL" ||
          getOtherRecipient(this.payout).source === "PAYPAL"
        );
      } else {
        if (this.payoutMethodStore.selected)
          return this.payoutMethodStore.selected.source === "PAYPAL";
        else return false;
      }
    },

    formattedGroupName() {
      return this.payoutStore?.selected?.merchant?.formattedGroupName;
    },
  },
  mounted() {
    if (this.isPaypal) {
      this.showPaypalLoader = true;
      this.startTimer();
    }
    if (this.isVisaPlus) {
      this.visaPlusPayeeName =
        this.payoutMethodStore.selected?.visaPlus?.payName;
      this.visaPlusAmount = currency(
        this.payout?.recipient[0]?.payments?.amount?.total,
        this.payout?.recipient[0]?.payments?.amount?.currency
      );
    }
  },
  beforeUnmount() {
    if (this.intervalId) clearInterval(this.intervalId);
  },

  methods: {
    done() {
      this.dialogPanelStore.hide();
      this.payoutStore.selected = null;
      this.payoutMethodStore.selected = null;
    },

    currentDate() {
      return format(new Date(), String(i18n.global.t("dateFormat.short")));
    },

    startTimer() {
      this.intervalId = setInterval(() => {
        this.checktrasactionStatus();
      }, 3 * this.currentTimespan * 1000);
    },

    async checktrasactionStatus() {
      this.currentTimespan++;
      await this.payoutStore.fetchSelected();

      if (!isPaymentInprogress(this.payout)) {
        clearInterval(this.intervalId);
        this.showPaypalLoader = false;
        if (this.isPaymentDeclined) {
          this.updatePageTitle;
        }
        if (!this.isPaymentDeclined && !this.inReview && !this.claimed) {
          this.displaygenricMessage = true;
        }
      } else if (this.currentTimespan > 10) {
        this.displaygenricMessage = true;
        this.showPaypalLoader = false;
        clearInterval(this.intervalId);
      }
    },
  },
};
</script>
<style>
.loadercontainer {
  border: 2px solid;
  border-radius: 12px;
  padding: 20px;
}

.enabledByVisaRegister {
  width: 25%;
  padding-top: 5%;
}
</style>
